.App {
  text-align: center;
}

.fullWidth {
  width: 100%;
  height: 100%;
}

.shadow {
  -moz-box-shadow:    inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow:         inset 0 0 10px #000000;
}

.divider {
  color: #fc2b66;
  font-weight: bold;
}

.grow {
  flex-grow: 1;
  text-align: center;
  vertical-align: center;
}

.flexMe {
  display: flex;
  flex-direction: "row";
}

.listItems {
  flex-direction: row;
}

.main {
  width: 80%;
  margin: auto;
  margin-bottom: 10%
}

.debug {
  background-color: #fc2b66;
  border-color: #000000;
  border-width: 1pt;
}

.rounded  {
  border-radius: 20px; 
  border: #282c34;
  border: 10px;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 30vh;
}

.center {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: center
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.clouds {
  background-image: url("https://res.cloudinary.com/numbershapes/image/upload/v1651317012/Clouds_iehfpx.png");
  background-size: cover;
}

.carousel {
  background-color: blue;
  width: 100vw
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
